<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import TablesCard from './TablesCard'

export default {
  name: 'activitysDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        regionCode: [],
        publishingType: [],
        publishingTypeCopy: [],
        sort: '',
        participantsNumber: ''
      },
      regionCode: [],
      actiyArr: [],
      tenant: []
    }
  },
  mounted() {
    const { id } = this.$route.query
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'regionCode'
    })

    getAction('/api/farmActivityType/list').then(res => {
      if (res.code == 200) {
        this.actiyArr = res.data.map(e => {
          return {
            ...e,
            value: e.id
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    })
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmActivity/detail?id=${id}`
        })
        .then(result => {
          this.detail.regionCode = [result.provinceCode, result.cityCode, result.areaCode]
          this.detail.dw = `${result.lon}，${result.lat}`
        })
    }
  },
  methods: {
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: [],
        onSuccess: data => {
          this.detail.lon = data.lng
          this.detail.lat = data.lat
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          this.$forceUpdate()
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            key: 'name',
            cols: 12,
            props: {
              maxLength: 76
            },
            rules: [
              {
                required: true
              }
            ]
          },

          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            key: 'sort',
            cols: 12,
            props: {
              min: 0,
              max: 100
            }
          },
          {
            name: '活动类型',
            type: 'select',
            key: 'activityTypeId',
            cols: 12,
            typeData: this.actiyArr
          },
          {
            name: '活动名额',
            type: 'inputNumber',
            key: 'participantsNumber',
            cols: 12,
            props: {
              min: 0
            }
          },
          {
            name: '对外公开',
            type: 'radioButton',
            key: 'visibleType',
            defaultValue: '0',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: 'Banner(最多4张)',
        data: [
          {
            type: 'upload',
            maxLength: 4,
            cols: 24,
            key: 'imgUrl',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24
          }
        ]
      }
    },
    getForm4() {
      return {
        title: '时间',
        type: 'cardForm',
        data: [
          {
            name: '活动开始时间',
            type: 'datePicker',
            key: 'beginTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss'
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '活动结束时间',
            type: 'datePicker',
            key: 'endTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss'
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '报名截止',
            type: 'datePicker',
            key: 'lastRegistTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss'
            },
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    getForm5() {
      return {
        title: '位置',
        type: 'cardForm',
        data: [
          {
            name: '经纬度 （例：120.184349，30.254460）',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true
              }
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                placeholder: '请输入经纬度以中文逗号隔开'
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap
                  }
                }
              }
            ]
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            colsType: {
              lg: 24,
              md: 24,
              sm: 24,
              xs: 24,
              xxl: 12,
              xl: 18
            },
            gutter: 5,
            rules: [
              {
                required: true
              }
            ],
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址'
                  }
                ]
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址'
              }
            ]
          }
        ]
      }
    },
    getForm6() {
      if (this.detail.publishingType instanceof Array) {
        return {
          type: 'row',
          children: [
            <TablesCard
              ref="platforms"
              release={this.detail.publishingType}
              palmDisplayList={this.detail.palmDisplayList}
              villageDisplayList={this.detail.villageDisplayList}
            />
          ]
        }
      }
    },
    getForm7() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            data={this.getLabelForm()}
            title="报名字段"
            pubBool={false}
            cols={24}
            style={{ marginBottom: '10px', minHeight: '200px' }}
            icons={this.labelOne}
            carTitle={'字段'}
          />
        ]
      }
    },
    getLabelForm() {
      return [
        {
          name: '报名字段',
          type: 'input',
          key: 'labelName'
        }
      ]
    },
    getString(str) {
      // eslint-disable-next-line prettier/prettier
      return str
        ? str
            .split(',')
            .filter(e => e)
            .join(',')
        : ''
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.regionCode[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            const codeData = this.getSumbitData()
            if (moment(data.beginTime) > moment(data.endTime)) {
              this.$message.warning('活动结束时间应在活动开始时间前！')
              return
            }
            if (moment(data.endTime) < moment(data.lastRegistTime)) {
              this.$message.warning('报名截止时间应在活动结束时间前！')
              return
            }

            if (!(data.lon && data.lat)) {
              this.$message.warning('请通过地位选择经纬度！')
              return false
            }

            let activityTypeName = (
              this.actiyArr.find(e => e.id == data.activityTypeId) || {
                activityTypeName: ''
              }
            ).activityTypeName

            let tenantArrList = JSON.parse(localStorage.getItem('tenantArr'))
            let tenantName = (
              tenantArrList.find(e => e.tenantId == localStorage.getItem('appCode')) || { tenantName: '' }
            ).tenantName

            let displayList = [
              {
                publishingType: '0',
                appCode: localStorage.getItem('appCode'),
                appName: tenantName
              }
            ]
            let arr = data.dw.split('，')
            data.lon = arr[0]
            data.lat = arr[1]

            let obj = {
              ...data,
              activityTypeName: activityTypeName,
              displayList,
              publishingManType: '0',
              farmSectionType: '0',
              publishingType: '0',
              ...codeData,
              upDown: this.detail.id ? this.detail.upDown : '0'
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/farmActivity',
                params: {
                  ...obj
                },
                isPost: false
              })
              .then(() => {
                this.$router.push('/publicInfo/activitys')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/publicInfo/activitys')
        }
      ]
      const right = [
        {
          name: ` ${this.detail.status == '1' ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            postAction(`/farmActivity/${this.detail.status == '1' ? 'down' : 'up'}?id=${this.detail.id}`).then(e => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.status == '1' ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push('/publicInfo/activitys')
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/farmActivity?id=${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/publicInfo/activitys')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          regionCode: this.regionCode
        }}
        form={this.detail}
        foot={this.getFoot()}
        data={[
          this.getForm1(),
          //   this.getForm6(),
          //   this.getForm7(),
          this.getForm4(),
          this.getForm5(),
          this.getForm2(),
          this.getForm3()
        ]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
