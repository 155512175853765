<script>
import { Card } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import DrawerFormSZNC from './DrawerFormSZNC'
import DrawerFormWLXC from './DrawerFormWLXC'
import { getAction } from '../../../command/netTool'

export default {
  props: {
    release: {
      type: Array,
      default: () => {
        return []
      }
    },
    palmDisplayList: {
      type: Array,
      default: () => {
        return []
      }
    },
    villageDisplayList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      tableDataSZNC: [],
      tableDataWLXC: []
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.tableDataSZNC = this.palmDisplayList.map(e => {
      return {
        ...e,
        activitys: true
      }
    })
    this.tableDataWLXC = this.villageDisplayList.map(e => {
      return {
        ...e,
        activitys: true,
        partys: true,
        volunteers: true
      }
    })
  },
  methods: {
    unQuery(arr) {
      let obj = {}
      let points = arr.reduce(function(item, next) {
        obj[next.palmId] ? '' : (obj[next.palmId] = true && item.push(next))
        return item
      }, [])
      return points
    },
    popupSZNC() {
      apiTool.showDrawer({
        title: '手掌农场展示功能',
        width: '720px',
        view: DrawerFormSZNC,
        viewProps: {
          dataCheck: this.tableDataSZNC
        },
        success: ({ data, setHidden }) => {
          let oldData = this.palmDisplayList
          let newData = data
          let newArr = []
          if (oldData.length == 0) {
            newArr = newData.map(e1 => {
              return {
                activitys: true,
                activityId: null,
                bonusPoints: null,
                farmSectionType: '0',
                id: null,
                palmId: e1.id,
                palmName: e1.name,
                publishingType: '0',
                villageId: null
              }
            })
          } else {
            newData.forEach(e1 => {
              if (e1.activitys) {
                oldData.forEach(e2 => {
                  if (e1.name == e2.palmName) {
                    newArr.push({
                      ...e2,
                      activitys: true
                    })
                  } else {
                    newArr.push({
                      activitys: true,
                      activityId: null,
                      bonusPoints: null,
                      farmSectionType: '0',
                      id: null,
                      palmId: e1.id,
                      palmName: e1.name,
                      publishingType: '0',
                      villageId: null
                    })
                  }
                })
              }
            })
          }

          this.tableDataSZNC = this.unQuery(newArr)
          setHidden()
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    popupWLXC() {
      apiTool.showDrawer({
        title: '未来乡村展示功能',
        width: '720px',
        view: DrawerFormWLXC,
        viewProps: {
          dataCheck: this.tableDataWLXC
        },
        success: ({ data, setHidden }) => {
          let oldData = this.villageDisplayList
          let newData = data
          let newArr = []
          if (oldData.length == 0) {
            newArr = newData.map(e1 => {
              return {
                activitys: e1.activitys,
                partys: e1.partys,
                volunteers: e1.volunteers,
                activityId: null,
                bonusPoints: e1.bonus_points,
                id: null,
                palmId: e1.id,
                palmName: e1.name,
                publishingType: '1',
                villageId: e1.villageId,
                farmSectionType: `${e1.activitys ? '0,' : ''}${e1.partys ? '1,' : ''}${e1.volunteers ? '2' : ''}`
              }
            })
          } else {
            newData.forEach(e1 => {
              if (e1.activitys || e1.partys || e1.volunteers) {
                oldData.forEach(e2 => {
                  if (e1.name == e2.palmName) {
                    newArr.push({
                      ...e2,
                      activitys: e1.activitys,
                      partys: e1.partys,
                      volunteers: e1.volunteers,
                      bonusPoints: e1.bonus_points,
                      farmSectionType: `${e1.activitys ? '0,' : ''}${e1.partys ? '1,' : ''}${e1.volunteers ? '2' : ''}`
                    })
                  } else {
                    newArr.push({
                      activitys: e1.activitys,
                      partys: e1.partys,
                      volunteers: e1.volunteers,
                      activityId: null,
                      bonusPoints: e1.bonus_points,
                      id: null,
                      palmId: e1.id,
                      palmName: e1.name,
                      publishingType: '1',
                      villageId: e1.villageId,
                      farmSectionType: `${e1.activitys ? '0,' : ''}${e1.partys ? '1,' : ''}${e1.volunteers ? '2' : ''}`
                    })
                  }
                })
              }
            })
          }
          this.tableDataWLXC = this.unQuery(newArr)
          setHidden()
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    returnTableCard1() {
      if (this.release.includes('0')) {
        return (
          <aside>
            <div>手掌农场</div>
            <div>
              {this.tableDataSZNC.map(e => {
                if (e.activitys) {
                  return (
                    <span>
                      {e.palmName}（{['活动列表', '党建版块', '志愿者版块'][e.farmSectionType]}）
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </div>
            <a-button class="button_box" onClick={() => this.popupSZNC()}>
              编辑展示位置
            </a-button>
          </aside>
        )
      } else {
        return null
      }
    },
    returnTableCard2() {
      if (this.release.includes('1')) {
        return (
          <aside>
            <div>未来乡村</div>
            <div>
              {this.tableDataWLXC.map((e, i) => {
                let isShow = e.farmSectionType.split(',')
                if (e.activitys || e.partys || e.volunteers) {
                  return (
                    <span>
                      {e.palmName}（{isShow.includes('0') && '活动列表 '}
                      {isShow.includes('1') && '党建版块 '}
                      {isShow.includes('2') && '志愿者版块 '}）
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </div>
            <a-button class="button_box" onClick={() => this.popupWLXC()}>
              编辑展示位置
            </a-button>
          </aside>
        )
      } else {
        return null
      }
    }
  },
  render() {
    const scopedSlots = {
      title: () => {
        return (
          <div class="div_box">
            <div class="sub-old">平台</div>
            <div class="title_copy">板块功能</div>
          </div>
        )
      }
    }
    return <Card {...{ scopedSlots }}>{[this.returnTableCard1(), this.returnTableCard2()]}</Card>
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding: 0 24px 0;
}
aside {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .button_box {
    width: 108px;
    height: 28px;
    background: inherit;
    background-color: rgba(24, 190, 107, 0);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(44, 140, 240, 1);
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #2c8cf0;
    text-align: center;
  }

  div {
    &:nth-of-type(1) {
      width: 10%;
    }
    &:nth-of-type(2) {
      width: 83%;
    }
  }
}
.div_box {
  display: flex;
}
.sub-old {
  width: 10%;
  height: 18px;
  font-family: 'PingFang SC Normal', 'PingFang SC';
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #515a6e;
}
.title_copy {
  width: 55%;
  height: 18px;
  font-family: 'PingFang SC Normal', 'PingFang SC';
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #515a6e;
}
</style>
